.labImg {
    width: calc(25%);
    padding: 0 !important;
}

@media not all and (min-resolution:.001dpcm) {
    .labImg {
        width: calc(25vw);
        padding: 0 !important;
    }
}

.imgDoble {
    width: calc(100vw/2);
    margin: 0 !important;
    padding: 0 !important;
}

@media (max-width: 700px) {
    .labImg {
        width: calc(100vw/2);
        margin: 0 !important;
        padding: 0 !important;
    }

    .imgDoble {
        width: calc(100vw)
    }
}