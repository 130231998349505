.colShop1{
    padding-left: 20px !important;
    padding-right: 10px !important;
}

.colShop2{
    padding-left: 10px !important;
    padding-right: 20px !important;
   
}

.RowShop{
    min-height: 100vh;
    padding-bottom: 20px !important;
    
}

.IniShop{
    display: block;
    min-height: 65px;
    width: 100%;
    z-index: 80;
    top: 0;
    position: fixed;
}

.extraShop{
    min-height:20px !important;
    display: block !important;
}


  
@media (max-width:700px){
    .IniShop{
        display: block;
        height: 35px;
        min-height: 35px;
    }

    .colShop1{
        padding-left: 8px !important;
        padding-right: 8px !important;
    }

    .colShop2{
        padding-left: 8px !important;
        padding-right: 8px !important;
       
    }

    .minMobil{
        display: block;
        
    }
    
}