.modalImg{
    width: 100% !important;
}

.modal-backdrop {
    background-color: rgb(0 0 0 / 0%) !important;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: auto;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    width: 100%;
    height: 100vh;
    outline: 0;
    align-items: center;

}

.modal-dialog {
   /* top: 1em;*/
    /* max-width: 68% !important;*/
   /* max-width: 70%!important;*/
   /* max-height: 90% !important;*/
    /*width: calc(100%);*/
   /* height: calc(100%);*/
    /*position: fixed;*/
   /* left: 0px;*/
/*
   max-width:  70vw !important;
   top: -28px;
   z-index: 99;
   overflow-x: hidden;
   overflow: hidden;
   scrollbar-width: none;*/

   /*max-width: 100% !important;*/


   position: relative;
   height: auto;
   display: flex;
   margin: 0 auto;
   align-items: center !important;
   pointer-events: none;

}

.modal-dialog-vertical {
    top: 2.25em;
    /* max-width: 68% !important;*/
    /*max-width: 76%!important;*/
    max-height: 90% !important;
}

.modal-content {
    /*
    border: 0 !important;
    border-radius: 0 !important;
    background-color: rgb(0 0 0 / 0%);
    padding: 2em;
    display: flex;
    align-items: center;
    justify-content: center;*/

    /*width: calc(80% - 4em);
    height: calc(90% - 4em);*/

    border: 0 !important;
    border-radius: 0 !important;
    background-color: rgb(0 0 0 / 0%);
    position: relative;
    display: flex;
    width: calc(100%);
    /* width: calc(100% - 1em);*/
    align-items: center;
    margin: 0 auto;
    outline: 0;
    vertical-align: middle !important;
}

.modal-dialog2 {
    max-height: 85vh !important;
    margin-left: auto;
    margin-right: auto;
    top: 20px !important;
    border: 0 !important;
    border-radius: 0 !important;
    outline: 0;
}


.modal-img {
    /*max-height: 84vh !important;*/
   /* margin-left: auto;
    margin-right: auto;*/
    /*width: calc(100% - 4em);*/
    /*height: calc(100% - 4em);*/

   /* max-height: 92vh !important;*/

   /* border: 0 !important;
    border-radius: 0 !important;
    outline: 0;*/
}

.modal-dialog2, .modal-especial, .modal-img {
    max-width: 100vw;
    max-height: 100vh !important;
    margin: 0 auto;
    display: table-cell;
    align-items: center;
    padding: 2%;
    vertical-align: middle;
}

.modal-especial {
   
    margin: 0 !important;
    border: 0 !important;
    border-radius: 0 !important;
    outline: 0;
}


.modal-content {
    /* top: -20px !important; */
}

@media (max-width: 700px) {
    .modal-dialog {
        top: 1.5em;
        max-width: 90% !important;
        margin: 1.75rem auto;
    }

    .modal-dialog2 { 
        top: 7em;
        max-width: 95% !important;
        
        min-height: 75vh !important;
        margin: 1.75rem auto;
    }

   
}

@media (min-width: 576px) {
.modal-dialog {
    /* top: 5em;
    max-width: 90% !important;
    margin: 1.75rem auto; */
}

}

.Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 0 !important;
    border-radius: 0 !important;
    background-color: rgb(0 0 0 / 0%);
    z-index: 998;
  }

.ReactModal{
   /* padding: 2em;*/
   width: calc(100%);
    height: calc(100%);
   
    top: 0px;
    left: 0px;
    z-index: 9999999;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;
    border: 0 !important;
    border-radius: 0 !important;
    background-color: rgb(0 0 0 / 0%);
    z-index: 999;
}

.lightboximg {
    padding: 2em;
    width: calc(100% - 2em);
    height: calc(100% - 2em);
    max-height: 100%;
    max-width: 100%;
    height: auto;
    width: auto;
}


 @media (min-width: 576px) { 
    .modal-dialog {
       max-width: 100% !important;
        margin: 0rem auto !important;
    }
} 