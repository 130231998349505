
.error-container {
    background-color: rgba(251, 208, 72);
    color: #fff;
  text-align: center;
  height: 100vh;
  -moz-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
}

@media (max-width: 480px) {
  .error-container {
    position: relative;
    top: 50%;
    height: initial;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
}

.error-container h1 {
  margin: 0;
  font-size: 130px;
  font-weight: 300;
}

@media (min-width: 480px) {
  .error-container h1 {
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
}

@media (min-width: 768px) {
  .error-container h1 {
    font-size: 220px;
  }
}

.return {
  color: rgba(255, 255, 255, 0.6);
  font-weight: 400;
  letter-spacing: -0.04em;
  margin: 0;
}

@media (min-width: 480px) {
  .return {
    position: absolute;
    width: 100%;
    bottom: 30px;
  }
}

.return a {
  padding-bottom: 1px;
  color: #fff;
  text-decoration: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.6);
  -webkit-transition: border-color 0.1s ease-in;
  transition: border-color 0.1s ease-in;
}

.return a:hover {
  border-bottom-color: #fff;
}