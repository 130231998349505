.Work{
    text-align: left;
    width: 70%;
    font-size: 12px;
    padding-left: 20px !important;
    padding-right: 20px !important;
    background-color: rgb(255, 255, 255);
    min-height: 100vh;
    margin-bottom: 20px !important;
}

.IniWork{
    display: block;
    height: 65px;
    width: 100%;
    z-index: 80;
    top: 0;
    position: fixed;
    background-color: white;
}



@media (max-width:700px){
    .Work{
        text-align: left;
        width: 70%;
        font-size: 12px;
        padding-left: 8px !important;
        padding-right: 8px !important;
        background-color: rgb(255, 255, 255);
        min-height: 100vh;
        margin-bottom: 20px !important;
        padding-top: 32px;
    }


    .IniWork{
        display: block;
        height: 35px;
        width: 100%;
        z-index: 80;
        top: 0;
        position: fixed;
        background-color: white;
    }
}