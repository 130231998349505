@font-face {
    font-family: fuente2;
    src: url(../Menu/fuente/Bureau\ Grot\ Bold.otf);

  }

 @font-face {
    font-family: per;
    src: url(../Menu/fuente/HelveticaNeue.ttc);

  }

  .IniPre{
    display: block;
    height: 65px;
    width: 100%;
    z-index: 80;
    top: 0;
    position: fixed;
    background-color: black;
}

.RowPre{
    background-color: black;
    /*height: auto !important;*/
    font-size: 1rem;
    padding-top: 80px !important;
    text-align: left;
    padding-bottom: 0px !important;

}

.ColPre{
    color: white;
    padding-left: 20px !important;
    padding-right: 20px !important;
}

.h3Pre{
    margin-bottom: 20px !important;
    font-size: 11.7px;
    font-family: fuente2;
    margin: 0;
    font-weight: 500;
   
}

.labelPre{
    margin-bottom: 20px !important;
    font-size: 12px;
    line-height: 1.3;
    font-family:per;
   
}
.labelPre1{
    margin-top: 5px;
    margin-bottom: 30px !important;
    line-height: 1.3;
    font-family: fuente2;
    font-size: 11.7px;
}

.subrayadoPre{
    text-decoration: underline;
    margin: 0;
    font-size: 12px;
    font-family:per;
}

@media (max-width: 700px) {
    .ColPre{
        color: white;
        padding-left: 8px !important;
        padding-right: 8px !important;
        flex-basis: auto;
    }
    .IniPre{
        display: block;
        height: 35px;
        width: 100%;
        z-index: 80;
        top: 0;
        position: fixed;
        background-color: black;
    }
    .RowPre{
        background-color: black;
        height: auto !important;
        font-size: 1rem;
        padding-top: 44px !important;
        text-align: left;
        padding-bottom: 0px !important;
    
    }
    .h3Pre{
        margin-bottom: 15px !important;
    }


    .labelPre1 {
        margin-top: 5px;
        margin-bottom: 25px !important;
    }


    .h3Pre{
        font-size: 12px;
    }
    
    .labelPre{
        font-size: 13.2px;
    }
    .labelPre1{
        font-size: 12px;
    }
    
    .subrayadoPre{
        font-size: 13.2px;
    }
    
}


@media (max-width: 850px){
    .RowPre{
    

        background-color: black;
        /*height: auto !important;*/
        font-size: 1rem;
        padding-top: 44px !important;
        text-align: left;
        padding-bottom: 0px !important;
    
    }
}

/*
@media (min-height: 950px){
    .RowPre{
        background-color: black;
      
       height: 100% !important; 
        font-size: 12px;
        padding-top: 80px !important;
        text-align: left;
    }
}*/

@media screen and (min-width: 250px) and (max-width: 900px) { 
    .RowPre{
        background-color: black;
      
       height: auto !important; 
        font-size: 12px;
        padding-top: 51px !important;
        text-align: left;
    }
}


.RowPre{
    min-height: 100vh !important;
    height: auto;
}